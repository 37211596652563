.about-wrapper{
    padding: 10% 10%;
    padding-top: 100px;
    @media #{$sm-layout} {
        padding: 0% 5%;
        padding-top: 100px;
    }
    @media #{$lg-layout} {
        
    }
    @media #{$large-mobile} {
    }
}
.about-inner{
    padding-top: 5%;
}
.description{
    font-size: x-large;
    font-weight: lighter;
    opacity: 0.7;
}
.about-area{
    background-color: $theme-color-light;
}