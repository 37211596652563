.pp-container{
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 100px;
    padding-top: 20px;
    margin-top: 15vh;
    width: 100%;
    a{
        font-weight: bold;
        color: $body-color;
        text-decoration: none;
    }
}
.go-home-pp{
    margin-top: 20px;
    align-self: center;
    width: fit-content;
}
